import type { ReportCard_ReportFragment } from '@/modules/reports/components/ReportCard/ReportCard.generated';
import WorkflowStatusBadge from '@/modules/workflows/components/WorkflowStatusBadge/WorkflowStatusBadge';
import { formatDateToMD_or_YYYYMD } from '@/utils/date/date';
import { gql } from '@apollo/client';
import { Flex, Icon, LinkBox, LinkOverlay, Stack, Text } from '@chakra-ui/react';
import { Link } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import { MdOutlineInsertDriveFile } from 'react-icons/md';

gql`
fragment ReportCard_Report on Report {
    id
    latestVersion {
        id
        title
    }
    firstVersion {
        id
        createdBy {
            id
            name
        }
        createdAt
    }
    workflowExecution {
        id
        currentStateId
    }
    reportTemplateVersion {
      id
      name
    }
}
`;

type Props = {
  report: ReportCard_ReportFragment;
  isCurrentItem?: boolean;
};

export default function ReportCard({ report, isCurrentItem }: Props) {
  const { t } = useTranslation();
  return (
    <LinkBox
      p={2}
      bg={isCurrentItem ? 'primary.50' : 'transparent'}
      _hover={{
        bg: 'neutral.50',
      }}
    >
      <Stack align='stretch' textAlign='left' gap={1}>
        <Text color='text.primary' fontSize='xs' fontWeight={700} display='flex'>
          <Icon as={MdOutlineInsertDriveFile} boxSize={4} mr='2px' />
          {report.reportTemplateVersion.name}
        </Text>
        <Flex alignItems='center' gap={2}>
          <LinkOverlay
            as={Link}
            to={`/reports/${report.id}`}
            fontSize='lg'
            color='gray.800'
            fontWeight='semibold'
          >
            {report.latestVersion.title}
          </LinkOverlay>
          <WorkflowStatusBadge stateId={report.workflowExecution.currentStateId} />
        </Flex>

        <Flex>
          <Text color='gray.500' fontSize='xs'>
            {formatDateToMD_or_YYYYMD(report.firstVersion.createdAt)}{' '}
            {t('created-by', { name: report.firstVersion.createdBy.name })}
          </Text>
        </Flex>
      </Stack>
    </LinkBox>
  );
}
